<template>
    <div>
		<b-modal ref="modalSendDG" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.envoyer") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-else>
				<div v-if="form_message !== ''" class="col-12 alert alert-warning">{{ form_message }}</div>

				<div class="col-12">
					<div class="form-group">
						<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
						<e-select
							v-model="template"
							id="model_id"
							track-by="model_label"
							label="model_label"
							:placeholder="labelTitleTemplate"
							:deselectLabel="deselectLabel"
							:selectedLabel="selectedLabel"
							:options="templates"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>

                    <div class="form-group mt-4">
                        <label for="mailtype_label">{{ $t('model.expeditor_label') }} *</label>
                        <e-select
                            v-model="finalExpeditor"
                            id="expeditor_id"
                            :placeholder="labelTitleExpeditor"
                            :options="finalExpeditors"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"        
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>

					<div class="form-group mt-4">
                        <label for="choose_recipient">{{ $t('global.choose_recipient') }} *</label>
                        <e-select
                            v-model="recipient"
							id="id"
							track-by="label"
							label="label"
                            :placeholder="labelTitleExpeditor"
                            :options="recipients"
                            :allow-empty="false"
                            :show-labels="false"        
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>

                    <b-alert show variant="warning" class="mt-2" v-if="nomail">
                        <h6>{{ $t("model.personnaliser_email") }}</h6>
                    </b-alert>

                    <b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.envoyer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
				</div>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Gynecologie from '@/mixins/Gynecologie.js'
import Model from "@/mixins/Model.js"

export default {
	name: "modalSendDG",
	mixins: [Gynecologie, Model],
	data () {
		return {
			templates: [],
			template: null,
			processing: false,
			form_message: '',
			modal_loaded: false,
            couples: [],
            finalExpeditors: [],
            finalExpeditor: null,
            nomail: false,
			recipient: null,
			recipients: [
				{id: 1, label: this.getTrad('tiers.principal')},
				{id: 2, label: this.getTrad('mouvement.residence')}
			],

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc"),
			labelTitleExpeditor: this.getTrad("model.search_expeditor"),
		}
	}, 
	methods: {
		async init_component() {
			this.processing = false
			this.template = null
			this.templates = []

			this.templates = await this.loadAllModel(0, ['bilanrepro_notification'])
			this.recipient = this.recipients.find(r => r.id == 1)

            const fromAddress = await this.loadAllFromAddress()
			await this.FormatFromAddress(fromAddress)

			this.modal_loaded = true
		},
        async FormatFromAddress(fromAddress){
			let array_mail = []
			let array_name = []
			this.finalExpeditors = []
			const selected = fromAddress["selected"]
			delete fromAddress["selected"]

			for (const [key, value] of Object.entries(fromAddress)){				
				if (value.licenceconfig_key === "from_address"){
					if (value.licenceconfig_value != '' || value.licenceconfig_value != null){
						const configValueAddress = value.licenceconfig_value.split(',')
						for (const address of configValueAddress){
							array_mail.push(address.trim())
						}
					} 					
				}
				if (value.licenceconfig_key === "from_name" ){
					if (value.licenceconfig_value != ''|| value.licenceconfig_value != null){
						const configValueName = value.licenceconfig_value.split(',')
						for (const name of configValueName){
							array_name.push(name.trim())
						}
					} 
				}
				if(array_mail.length > 0 && array_name.length > 0){
					if(array_name[0] === 'Groomy'){
						this.nomail = true
					}
					break;
				}
			}

			for (const index in array_mail){
				this.finalExpeditors.push(array_mail[index]+ " / " + array_name[index])
			}

			this.finalExpeditor = this.finalExpeditors[0]
		},
		openModal(params) {
            this.couples = params
			this.$refs.modalSendDG.show()
		},
		closeModal() {
			this.$refs.modalSendDG.hide()
		},
		async checkForm() {
			if(this.template && this.recipient) {
				this.processing = true

                let params_to_send = []
                this.couples.forEach(couple => {
                    params_to_send.push(couple.seasonmarestallion_id)
                })

                await this.sendMailDG(this.template.model_id, params_to_send, this.finalExpeditor, this.recipient.id)
                    .then((res) => {
						this.processing = false
						this.$emit('submit')
						if(res != 'error') {
							this.successToast('gynecologie.send_dg_success')
                        	this.closeModal()
						}
                    })
			}
			else {
				this.failureToast("formulaire.erreur_champs_non_remplis")
			}
		}
	},
	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}
</script>